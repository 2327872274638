<template lang="html">
  <div class="manager-salary">
    <IxRes>dlgCert.social.salary.hints.noSalaryInfoForManagers</IxRes>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.manager-salary {
  font-style: italic;
  color: gray;
  display: flex;
  align-items: center;
  min-height: 24px; // NOTE layout compatibility with inputs
}
</style>
