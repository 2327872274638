<template lang="html">
  <div>
    <DlgHeader>
      <IxRes>dlgCert.social.salary.headers.payGrade1</IxRes>
    </DlgHeader>
    <ul>
      <li><IxRes>dlgCert.social.salary.hints.payGrade1.tasks</IxRes></li>
      <li><IxRes>dlgCert.social.salary.hints.payGrade1.trainingTime</IxRes></li>
      <li><IxRes>dlgCert.social.salary.hints.payGrade1.education</IxRes></li>
    </ul>

    <DlgHeader>
      <IxRes>dlgCert.social.salary.headers.payGrade2</IxRes>
    </DlgHeader>
    <ul>
      <li><IxRes>dlgCert.social.salary.hints.payGrade2.tasks</IxRes></li>
      <li><IxRes>dlgCert.social.salary.hints.payGrade2.education</IxRes></li>
    </ul>

    <DlgHeader>
      <IxRes>dlgCert.social.salary.headers.payGrade3</IxRes>
    </DlgHeader>
    <ul>
      <li><IxRes>dlgCert.social.salary.hints.payGrade3.tasks</IxRes></li>
      <li><IxRes>dlgCert.social.salary.hints.payGrade3.authority</IxRes></li>
      <li><IxRes>dlgCert.social.salary.hints.payGrade3.management</IxRes></li>
      <li><IxRes>dlgCert.social.salary.hints.payGrade3.education</IxRes></li>
    </ul>

    <DlgHeader>
      <IxRes>dlgCert.social.salary.headers.payGrade4</IxRes>
    </DlgHeader>
    <ul>
      <li><IxRes>dlgCert.social.salary.hints.payGrade4.tasks</IxRes></li>
      <li><IxRes>dlgCert.social.salary.hints.payGrade4.education</IxRes></li>
    </ul>
  </div>
</template>

<script>
import DlgHeader from '@dlg-cert/components/forms/elements/DlgHeader'

export default {
  components: {
    DlgHeader
  }
}
</script>

<style lang="scss" scoped>
</style>
