<template lang="html">
  <div class="salary-inputs">
    <DlgVueSelectize
      :value="salaryData.payGrade"
      :options="[1, 2, 3, 4]"
      :fields="fields"
      allow-empty
      :combobox-labeled-item="itemRenderFunction"
      @input="$emit('update', {id, year, name: 'payGrade', value: $event})"
    />
    <DlgNumericInput
      :value="salaryData.annualSalary"
      addon="€"
      :rule="min(0)"
      hide-description integer
      @input="$emit('update', {id, year, name: 'annualSalary', value: $event})"
    />
  </div>
</template>

<script>
import DlgNumericInput from '@dlg-cert/components/forms/elements/DlgNumericInput'
import DlgVueSelectize from '@dlg-cert/components/forms/elements/DlgVueSelectize'

import StandardRulesMixin from '@components/forms/StandardRulesMixin'

export default {
  components: {
    DlgNumericInput,
    DlgVueSelectize
  },
  mixins: [
    StandardRulesMixin
  ],
  props: {
    id: String,
    salaryData: Object,
    year: Number,
    name: String
  },
  computed: {
    fields () {
      return {
        // needed else empty thow error and whow Unkown Source
        text: x => `Gehaltsgruppe ${x}`
      }
    }
  },
  methods: {
    itemRenderFunction (data, escape) {
      return `<div><span>GG ${escape(data.value)}</span></div>`
    }
  }
}
</script>

<style lang="scss" scoped>
.salary-inputs {
  display: flex;
  align-items: center;

  > :not(:first-child) {
    margin-left: 0.25em;
  }

  ::v-deep .selectize-control {
    width: 60px;
  }
}
</style>
