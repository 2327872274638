<template lang="html">
  <LoadingWrapper :loading="loading">
    <div v-if="data" class="salary-overview">
      <DlgHeader>
        <IxRes>dlgCert.social.salary.headers.salary</IxRes>
      </DlgHeader>

      <div class="salary-grid" :class="{'show-pay-grades': showPayGrades}">
        <div class="id-header">
          <DlgInputDescription name="id" />
        </div>
        <div class="grid-header salary-header">
          <DlgInputDescription name="salary" has-description />

          <a href="#" @click.prevent="showPayGrades = !showPayGrades">
            <IxRes v-if="showPayGrades">dlgCert.social.salary.buttons.hidePayGrades</IxRes>
            <IxRes v-else>dlgCert.social.salary.buttons.showPayGrades</IxRes>
          </a>
        </div>
        <div class="grid-header">
          <DlgInputDescription name="hours" has-description />
        </div>
        <div class="grid-header">
          <DlgInputDescription name="vacation" has-description />
        </div>

        <div class="year-spacer" />
        <template v-for="i in 3">
          <span
            v-for="j in 3"
            :key="`${i}_${j}`"
            class="year"
            :class="{first: j === 1}"
          >
            {{ currentYear - 3 + j }}
          </span>
        </template>

        <template v-for="(employee, rowIndex) of data.employees">
          <div :key="`${employee.id}-id`" class="employee-id">
            {{ employee.employeeId }}
          </div>
          <template v-if="employee.isEmployer">
            <div :key="`${employee.id}-salary`" class="first manager-salary">
              <ManagerSalaryLine />
            </div>
            <span v-for="i in 2" :key="`${employee.id}-dummy-${i}`" class="dummy" />
          </template>
          <template v-else>
            <div
              v-for="(year, yearIndex) of years"
              :key="`${employee.id}-salary-${year}`"
              :class="{first: yearIndex === 0}"
            >
              <SalaryInput
                :id="employee.id"
                :year="year"
                :salary-data="employee.salaryData[yearIndex]"
                @update="updateYearlyValue"
              />
            </div>
          </template>
          <div
            v-if="showPayGrades && rowIndex === 0"
            :key="`pay-grade-help-${rowIndex}`"
            class="first pay-grade-help"
            :style="{gridRow: `span ${data.employees.length + 1}`}"
          >
            <PayGradeDetails @close="showPayGrades = false" />
          </div>
          <template v-else-if="!showPayGrades">
            <div
              v-for="(year, yearIndex) of years"
              :key="`${employee.id}-hours-${year}`"
              :class="{first: yearIndex === 0}"
            >
              <DlgNumericInput
                :value="employee.salaryData[yearIndex].annualHours"
                addon="h"
                :rule="min(0)"
                hide-description integer
                @input="updateYearlyValue({id: employee.id, year, name: 'annualHours', value: $event})"
              />
            </div>

            <div
              v-for="(year, i) of years"
              :key="`${employee.id}-vacation-${year}`"
              :class="{first: i === 0}"
            >
              <DlgNumericInput
                :value="employee.salaryData[i].usedVacationDays"
                addon="Tage"
                :rule="min(0)"
                hide-description integer
                @input="updateYearlyValue({id: employee.id, year, name: 'usedVacationDays', value: $event})"
              />
            </div>
          </template>
        </template>
      </div>

      <IxRes>dlgCert.social.salary.hints.newerEmployeesAreUnlisted</IxRes>
    </div>
  </LoadingWrapper>
</template>

<script>
import moment from 'moment'

import LoadingWrapper from './shared/LoadingWrapper'
import DlgHeader from './elements/DlgHeader'
import DlgNumericInput from './elements/DlgNumericInput'
import DlgInputDescription from './elements/DlgInputDescription'
import ManagerSalaryLine from './elements/social/salary/ManagerSalaryLine'
import PayGradeDetails from './elements/social/salary/PayGradeDetails'
import SalaryInput from './elements/social/salary/SalaryInput'

import StandardRulesMixin from '@components/forms/StandardRulesMixin'
import ApiMixin from '@dlg-cert/mixins/ApiMixin'

export default {
  components: {
    LoadingWrapper,
    DlgInputDescription,
    ManagerSalaryLine,
    PayGradeDetails,
    DlgNumericInput,
    SalaryInput,
    DlgHeader
  },
  mixins: [
    ApiMixin,
    StandardRulesMixin
  ],
  data () {
    return {
      showPayGrades: false
    }
  },
  computed: {
    apiUrl () {
      return `/api/v2/dlg-cert/${this.$route.params.orgUnitId}/social/${this.currentYear}/salary`
    },
    currentYear () {
      return moment().year()
    },
    years () {
      return [
        this.currentYear - 2,
        this.currentYear - 1,
        this.currentYear
      ]
    }
  },
  methods: {
    async updateYearlyValue ({id, year, name, value}) {
      this.data.employees.find(x => x.id === id).salaryData[this.years.indexOf(year)][name] = value

      try {
        const path = `${id}/${year}/${name}`
        if (value === null) {
          await this.api.delete(path)
        } else {
          await this.api.put(`${path}/${value}`)
        }
      } catch (error) {
        console.error(error)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.salary-grid {
  display: grid;
  grid-template-columns: auto repeat(3, 1.6fr) repeat(3, 1.3fr) repeat(3, 1fr);

  > * {
    padding: 0.25em 0.5em;
    display: flex;
    align-items: center;
  }

  .id-header, .grid-header {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0.9em;
  }

  .grid-header {
    grid-column: span 3;
    border-left: 1px solid gray;
  }

  .id-header, .employee-id {
    justify-content: flex-end;
  }

  .salary-header {
    justify-content: space-between;

    a {
      text-transform: none;
      font-weight: normal;
    }
  }

  .year-spacer, .year {
    border-bottom: 1px solid gray;
  }

  .first {
    border-left: 1px solid gray;
  }

  .manager-salary {
    grid-column: span 3;
  }

  .dummy {
    position: absolute;
  }

  // > :not(:nth-child(n + 15)) {
  //   background-color: red;
  // }

  &.show-pay-grades {
    .pay-grade-help {
      grid-column: 5 / -1;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    .year:nth-child(n + 9), .grid-header:not(.salary-header) {
      color: lightgray;

      ::v-deep a {
        color: lightgray;
      }
    }

    // one rule for each column
    @for $i from 1 through 4 {
      // 8n to repeat for every 2 rows (4 cells per row)
      // $i for each cell
      // 19 offset to skip 14 header elements, first row, and pay grade help
      > :nth-child(8n + #{$i + 19}) {
        background-color: lighten(lightgray, 10%);
      }
    }
  }

  &:not(.show-pay-grades) {
    // one rule for each column
    @for $i from 1 through 10 {
      // 20n to repeat for every 2 rows (10 cells per row)
      // $i for each cell
      // 24 offset to skip 14 header elements and first row
      > :nth-child(20n + #{$i + 24}) {
        background-color: lighten(lightgray, 10%);
      }
    }
  }
}
</style>
